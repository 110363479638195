// components/feedback/loading-skeletons/SideDrawerSkeleton.js
import React from "react";

const SideDrawerSkeleton = () => (
	<nav className="fixed top-16 left-0 w-56 bg-white shadow-md h-[calc(100vh-64px)] border-r border-gray-300 hidden md:block overflow-y-auto">
		<div className="px-3 py-8 animate-pulse">
			<div className="font-semibold mb-4 h-6 bg-gray-200 rounded w-24"></div>
			<div className="mb-2 h-6 bg-gray-200 rounded w-32"></div>
			<div className="mb-2 h-6 bg-gray-200 rounded w-28"></div>
			<div className="mb-2 h-6 bg-gray-200 rounded w-36"></div>
			<div className="mb-2 h-6 bg-gray-200 rounded w-32"></div>
			<div className="h-6 bg-gray-200 rounded w-20"></div>
		</div>
	</nav>
);

export default SideDrawerSkeleton;
