// pages/_app.js
import { UserProvider } from "@auth0/nextjs-auth0/client";
import { QueryClientProvider } from "@tanstack/react-query";
import AuthWrapper from "@/components/auth/AuthWrapper";
import { queryClient } from "@/utils/queryClient";
import "@/styles/globals.css";
import useGlobalErrorHandler from "@/hooks/useGlobalErrorHandler";
import useBrowserCheck from "@/hooks/useBrowserCheck";
import BrowserNotSupported from "@/components/feedback/BrowserNotSupported";
import Head from "next/head";
import { useState, useEffect } from "react";

function App({ Component, pageProps }) {
	const [nonce, setNonce] = useState("");

	useEffect(() => {
		const metaNonce = document.querySelector("meta[name='csp-nonce']");
		if (metaNonce) {
			setNonce(metaNonce.getAttribute("content"));
		}
	}, []);

	const isModernBrowser = useBrowserCheck();

	useGlobalErrorHandler();

	if (!isModernBrowser) {
		return <BrowserNotSupported />;
	}

	return (
		<>
			<Head>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<title>Rent IQ</title>
			</Head>
			<QueryClientProvider client={queryClient}>
				<UserProvider
					useRefreshTokens={true}
					authorizationParams={{
						scope: "openid profile email offline_access",
					}}
				>
					<AuthWrapper nonce={nonce}>
						<Component {...pageProps} />
					</AuthWrapper>
				</UserProvider>
			</QueryClientProvider>
		</>
	);
}

export default App;
