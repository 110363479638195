// hooks/useBrowserCheck.js
import { useState, useEffect } from "react";
import Bowser from "bowser";

let cachedIsModernBrowser = null;

const useBrowserCheck = () => {
	const [isModernBrowser, setIsModernBrowser] = useState(true);

	useEffect(() => {
		// If cachedIsModernBrowser is not null, it means the check has already been performed
		// during this browser session (i.e., since the tab was opened). We use the cached value
		// to avoid rerunning the browser check, ensuring it only runs once per session.
		if (cachedIsModernBrowser !== null) {
			setIsModernBrowser(cachedIsModernBrowser);
			return;
		}

		if (typeof window !== "undefined") {
			const browser = Bowser.getParser(window.navigator.userAgent);
			const isValidBrowser = browser.satisfies({
				chrome: ">=60",
				firefox: ">=55",
				safari: ">=10",
				edge: ">=15",
				opera: ">=50",
				"internet explorer": ">=11",
				yandexbrowser: ">=20",
				vivaldi: ">=2.0",
				"samsung internet": ">=10",
				firefox_mobile: ">=55",
				chrome_mobile: ">=60",
				safari_mobile: ">=10",
				edge_mobile: ">=15",
				opera_mobile: ">=50",
				brave: ">=1.0",
			});

			// Cache the result for the current session
			cachedIsModernBrowser = isValidBrowser;
			setIsModernBrowser(isValidBrowser);
		}
	}, []);

	return isModernBrowser;
};

export default useBrowserCheck;
