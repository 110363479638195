// src/hooks/users/useGetUser.js
import { useQuery } from "@tanstack/react-query";
import { getUser } from "@/services/users/getUser";
import { logErrorToServer } from "@/utils/logErrorToServer";

export const useGetUser = ({ auth0UserId, accessToken }) => {
	return useQuery({
		queryKey: ["user", auth0UserId],
		queryFn: () => getUser({ auth0UserId, accessToken }),
		enabled: !!auth0UserId && !!accessToken,
		staleTime: 30 * 60 * 1000, // 30 minutes
		cacheTime: 60 * 60 * 1000, // 1 hour
		onError: (error) => {
			const errorDetails = {
				message: error.message || "An unknown error occurred",
				stack: error.stack || "No stack trace available",
				type: "Fetch Error",
				additionalInfo: { detail: "Error while fetching user" },
			};
			logErrorToServer({ errorDetails, accessToken, auth0UserId });
		},
	});
};
