// components/feedback/loading-skeletons/NavbarSkeleton.js
import React from "react";

const NavbarSkeleton = () => (
	<nav className="fixed top-0 left-0 w-full z-10 flex justify-between bg-white items-center border-b border-gray-300 px-4 sm:px-6 py-4 animate-pulse">
		<div className="h-6 bg-gray-200 rounded w-32"></div>
		<div className="flex items-center">
			<div className="h-6 bg-gray-200 rounded w-20 mr-4"></div>
			<div className="h-10 w-10 bg-gray-200 rounded-full"></div>
		</div>
	</nav>
);

export default NavbarSkeleton;
