// utils/logErrorToServer.js
import { shouldLogError } from "@/utils/errorLogDebouncer.js";
import { encryptLoggerKey } from "@/utils/errorLoggerEncryption";

export const logErrorToServer = async ({ errorDetails, accessToken, auth0UserId }) => {
	const apiBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
	if (!shouldLogError(errorDetails.message)) {
		return null;
	}
	try {
		const apiUrl = `${apiBaseUrl}/api/v1/account/errors`;
		const userAgent = errorDetails.userAgent || (typeof navigator !== "undefined" ? navigator.userAgent : "Unknown");
		const currentUrl = errorDetails.url || (typeof window !== "undefined" ? window.location.href : "Unknown");
		const domainName = "app.rentiq.com";

		// Array of specific error messages to check
		const specificErrors = [
			"Resource failed to load: https://app.rentiq.com/js/gtag.js",
			"Resource failed to load: https://app.rentiq.com/_next/static/chunks/195.12db2b6bd29fa77b.js",
			// Add more specific error messages here as needed
		];

		const isSpecificError = specificErrors.some((error) => errorDetails.message.includes(error));
		if (isSpecificError) {
			return null;
		}

		// Get encrypted key for authentication
		const encryptedKey = await encryptLoggerKey();

		// Build headers object
		const headers = {
			"Content-Type": "application/json",
			"X-Error-Logger-Key": encryptedKey,
		};

		// Only add Authorization header if accessToken is provided
		if (accessToken) {
			headers.Authorization = `Bearer ${accessToken}`;
		}

		const response = await fetch(apiUrl, {
			method: "POST",
			headers,
			body: JSON.stringify({
				error_message: errorDetails.message,
				error_stack: errorDetails.stack,
				error_type: errorDetails.type,
				auth0_user_id: auth0UserId || null,
				user_agent: userAgent,
				url: currentUrl,
				domain_name: domainName,
				additional_info: JSON.stringify(errorDetails.additionalInfo),
			}),
		});

		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}
		return await response.json();
	} catch (error) {
		return null;
	}
};
