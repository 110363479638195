// components/layout/PageLayout.js
import { useUser } from "@auth0/nextjs-auth0/client";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import ErrorMessage from "@/components/feedback/ErrorMessage";
import LoadingState from "@/components/feedback/LoadingState";
import { useAccessTokenQuery } from "@/hooks/useAccessTokenQuery";
import { useGetUser } from "@/hooks/users/useGetUser";
import GoogleAnalytics from "@/components/layout/GoogleAnalytics";
import NavbarSkeleton from "@/components/feedback/loading-skeletons/NavbarSkeleton";
import SideDrawerSkeleton from "@/components/feedback/loading-skeletons/SideDrawerSkeleton";

const NavbarWithNoSSR = dynamic(() => import("@/components/layout/Navbar"), { ssr: false });
const SideDrawerWithNoSSR = dynamic(() => import("@/components/layout/SideDrawer"), { ssr: false });

export const PageLayout = React.memo(({ children, nonce }) => {
	const router = useRouter();
	const { pathname } = router;

	// Fetch user details using the useUser hook
	const { user, error: errorUser, isLoading: isLoadingUser } = useUser();

	const auth0UserId = user?.sub;

	// Fetch the access token using a custom hook
	const { data: accessToken, isLoading: isLoadingAccessToken } = useAccessTokenQuery();

	// Memoize the isRentIQAdmincheck as it depends on user data
	const isRentIQAdmin = useMemo(() => {
		const namespace = process.env.NEXT_PUBLIC_AUTH0_NAMESPACE;
		return user && user[namespace]?.includes("RentIQAdmin");
	}, [user]);

	const {
		data: userData,
		isLoading: isLoadingUserData,
		isError: isErrorUserData,
	} = useGetUser({ accessToken, auth0UserId });

	// Conditionally apply the left margin only if the user is logged in
	const mainClass = useMemo(() => (user ? "flex-1 overflow-auto ml-0 md:ml-56" : "flex-1 overflow-auto"), [user]);

	// Determine if any of the loading states are true
	const isLoadingAny = isLoadingUser || isLoadingAccessToken || isLoadingUserData;

	// Determine if there is any error
	const isErrorAny = errorUser || isErrorUserData;

	if (isErrorAny) return <ErrorMessage />;

	return (
		<div className="flex flex-col min-h-screen pt-16">
			{pathname !== "/login" && (
				<>{isLoadingAny ? <NavbarSkeleton /> : <NavbarWithNoSSR organization={userData?.organizations} />}</>
			)}
			<div className="flex flex-1">
				{user && (
					<>
						{isLoadingAny ? (
							<SideDrawerSkeleton />
						) : (
							<SideDrawerWithNoSSR isRentIQAdmin={isRentIQAdmin} userProperties={userData?.organizations?.properties} />
						)}
					</>
				)}
				<main className={mainClass}>{isLoadingAny ? <LoadingState /> : children}</main>
			</div>
			<GoogleAnalytics nonce={nonce} />
		</div>
	);
});

PageLayout.displayName = "PageLayout";
