// utils/errorLogDebouncer.js

/**
 * errorDebouncer
 *
 * This utility function helps in debouncing and deduplicating error logging across the application.
 * It ensures that similar errors are not logged repeatedly within a short time frame, thereby reducing
 * noise in error logs and preventing excessive logging, especially for recurring or transient issues.
 *
 * Usage:
 * - Call the `shouldLogError` function before attempting to log an error.
 * - The function compares the new error message with the last logged error and checks if the debounce
 *   period (5 minutes by default) has passed since the last log.
 * - If the error is new or the debounce period has expired, the function returns `true`, allowing
 *   the error to be logged. Otherwise, it returns `false`, and the logging is skipped.
 *
 * Why use this:
 * - Helps prevent flooding your logging system with duplicate errors.
 * - Reduces server load and log noise, making it easier to identify and respond to unique issues.
 *
 * Example:
 * ```
 * if (shouldLogError(errorDetails.message)) {
 *   // Proceed to log the error
 * }
 * ```
 */

const lastLoggedError = {
	message: null,
	timestamp: 0,
};

const debounceTimeMs = 5 * 60 * 1000; // 5 minutes debounce time

export const shouldLogError = (message) => {
	const now = Date.now();

	if (lastLoggedError.message !== message || now - lastLoggedError.timestamp > debounceTimeMs) {
		lastLoggedError.message = message;
		lastLoggedError.timestamp = now;
		return true;
	}
	return false;
};
